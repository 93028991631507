import * as React from "react"
import { Formik } from "formik"
const axios = require("axios").default

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const [msg, setMsg] = React.useState("")
  const [isSent, setIsSent] = React.useState(false)

  return (
    <Layout
      className="text-center"
      style={{ backgroundColor: "#eee4da", overflowY: "scroll" }}
    >
      <Seo title="Contact Us" />
      <div
        className="max-w-4xl mx-auto px-4 pb-24 tracking-wider pt-64 md:pt-24"
        style={{ color: "#958e74" }}
      >
        <h1 className="text-3xl md:text-5xl font-semibold text-primary font-header tracking-widest">
          Project Information
        </h1>
        <div className="py-8 text-base md:text-xl font-semibold text-primary font-body">
          <table className="w-full text-sm table-auto text-left space-y-16 p-8">
            <Row title="Project Name:">Claydence</Row>
            <Row title="District:">15</Row>
            <Row title="Developer:">
              <b>Hong How Land Pte Ltd</b>
              <div className="text-xs">
                JV of Hong How Corporation Pte Ltd & Marrion Capital Pte Ltd
              </div>
            </Row>
            <Row title="Address:">99 Still Road, Singapore 423989</Row>
            <Row title="Tenure:">Freehold (Estate in Fee Simple)</Row>
            <Row title="Site Area:">2187.1 sqm / 23541 sqft</Row>
            <Row title="Type of Development:">
              One Block of 5-storey Apartment with basement, attic, swimming
              pool and communal facilities
            </Row>
            <Row title="Unit Mix">
              <div className="overflow-scroll">
                <table className="unitmix table-auto">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Number of bedrooms</th>
                      <th>Area (sqft)</th>
                      <th>Number of units</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>A</td>
                      <td>1</td>
                      <td>614</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>B</td>
                      <td>2</td>
                      <td>786</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>B3 Penthouse</td>
                      <td>2</td>
                      <td>980</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>B1</td>
                      <td>2+1</td>
                      <td>872</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>B2</td>
                      <td>2+1</td>
                      <td>915</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>C</td>
                      <td>3</td>
                      <td>1076</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>C3 (Premium) Penthouse</td>
                      <td>3</td>
                      <td>1475</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>C1</td>
                      <td>3+1</td>
                      <td>1206</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>C2 (Premium)</td>
                      <td>3+1</td>
                      <td>1313</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>D1 (Premium) Penthouse</td>
                      <td>4</td>
                      <td>2185</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>D Penthouse</td>
                      <td>4+1</td>
                      <td>2164</td>
                      <td>1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
            <Row title="No. Of Units:">28 Apartments</Row>
            <Row title="No. Of Carparks:">26 + 1 accessible lot</Row>
            <Row title="Plot Ratio:">1.46</Row>
            <Row title="Architect:">DS Architects Pte. Ltd.</Row>
            <Row title="Design Consultant:">Wallflower Pte Ltd</Row>
            <Row title="Landscape Consultant:">
              Coen Design International Pte Ltd
            </Row>
            <Row title="Main Contractor:">Boon Tian Contractor Pte Ltd</Row>
            <Row title="Conveyance Solicitor:">Rajah & Tann Singapore LLP</Row>
            <Row title="Facilities:">
              Attic -Lap Pool, Pool Deck, Jacuzzi, Gym, 1st Storey -Drop Off
              Plaza, Multi-purpose Room, BBQ corner, Intensive landscaping
              including heritage trees and edible garden.
            </Row>
            <Row title="Developer Licence:">C1443</Row>
            <Row title="Project Account No.:">
              United Overseas Bank Ltd for Project Account No. 769-322-067-3 of
              Hong How Land Pte. Ltd.
            </Row>
            <Row title="Expected Date of TOP:">28 April 2026</Row>
            <Row title="Expected Date of Legal Completion:">28 April 2029</Row>
          </table>
        </div>
      </div>
    </Layout>
  )
}

function Row({ className, title, children }: any) {
  return (
    <tr className="even:bg-white/30">
      <td className="p-2">{title}</td>
      <td className="p-2">{children}</td>
    </tr>
  )
}

export default IndexPage
